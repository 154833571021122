import React from "react";
import {
  Routes,
  Route,

} from "react-router-dom";
import Technical from "../components/technical/Technical";
import Financial from "../components/financial_services/Financial";
import Home from "../Home";
import Contact from "../components/contact/Contact";

export default function AppRoutes() {
  return (
    
      <div>
        <Routes>

          <Route path="/financial-services" element={<Financial />}></Route>
          <Route path="/technical-services" element={<Technical />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/" element={<Home/>}>
            
          </Route>
        </Routes>
      </div>
    
  );
}

