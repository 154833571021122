
export const ServicesData = {
    financialServices : [
       {
          id: 1,
          title: 'Financial Projection',
          description: 'We help you project the expected revenues, expenses, and cash flows of a business over a forecast period. ',
          image:'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80',
       },{
          id: 2,
          title: 'Business Consulting',
          description: "Our Business Consulting Services are structured to address a company's end-to-end business needs.",
          image:'https://images.unsplash.com/photo-1551836022-4c4c79ecde51?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80',
       },{
          id: 3,
          title: 'Startup Establishment',
          description: 'When planning to expand your startup, choosing the right partner to tackle the linguistic aspect of the process is crucial.',
          image:'https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2274&q=80',
       },
    
     ],
     techServices : [
       {
          id: 1,
          title: 'Web Development',
          description: 'Develop Web app that feels unique from each angle and provides smooth user experience',
          image:'https://images.unsplash.com/photo-1547658719-da2b51169166?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&h=1200&q=80',
       },
       {
          id: 2,
          title: 'Mobile App Development',
          description: 'A mobile app that will boost your business, know how we work to develop your next successful application.',
          image:'https://images.unsplash.com/photo-1546054454-aa26e2b734c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1760&q=80',
       },
       {
          id: 3,
          title: 'Cloud Computing',
          description: 'Keeping your online presence all the time is most important, and here we provide robust cloud services',
          image:'https://images.unsplash.com/photo-1573164713988-8665fc963095?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2369&q=80',
       },

     ],
       
    
    };