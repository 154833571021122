import React, { Component } from "react";

export default class WhyUsBullet extends Component {
  render(props) {
    return (
      <div>
        <div
          className={`flex flex-wrap items-center justify-end gap-10 -mx-3 ${this.props.styleClass}`}
        >
          {/* Div for text */}
          <div className="">
            <h3 className="dark:text-white">{this.props.title}</h3>
          </div>
          {/* Div for Icon */}
          <div className={`${this.props.size} text-kushLight-primary`}>
            {this.props.icon}
          </div>
        </div>
      </div>
    );
  }
}
