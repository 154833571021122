import React, { Component } from "react";

export default class WorkingProcessItem extends Component {
  render(props) {
    return (
      <div className="mt-24">
        <div className="group flex justify-center items-center transform hover:scale-110 transition-all ease-in-out duration-500">
          <div className="absolute rounded-full h-24 w-24 bg-kushLight-primary opacity-30 flex items-center justify-center"></div>
          <div className="absolute rounded-full h-20 w-20 bg-kushLight-primary opacity-30 flex items-center justify-center"></div>
          <div className="absolute rounded-full h-16 w-16 bg-kushLight-primary opacity-30 flex items-center justify-center"></div>
          <div className="absolute rounded-full h-12 w-12 bg-kushLight-primary opacity-30  flex items-center justify-center"></div>
          <div className="absolute rounded-full h-8 w-8 bg-kushLight-primary opacity-80 flex items-center justify-center">
            <h1 className="text-white font-bold text-2xl">
              {this.props.count}
            </h1>
          </div>
        </div>
        <div className="mt-16 dark:text-white text-center">
          <h1 className="text-lg">{this.props.title}</h1>
          <p className="text-xs mt-2">{this.props.subtitle}</p>
        </div>
      </div>
    );
  }
}
