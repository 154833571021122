import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class BorderButton extends Component {
    render(props) {
        return (
            <div>
                <Link
                  className="mr-2 inline-block px-4 py-3 text-xs dark:text-white text-kushLight-primary hover:text-blue-700 font-semibold leading-none border border-kushLight-primary hover:border-blue-300 rounded"
                  to={this.props.to}
                >
                  {this.props.title}
                </Link>
            </div>
        )
    }
}
