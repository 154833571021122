import React, { Component } from "react";

import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";

import WhyChooseUsImage from "../assets/img/images/banner2.svg";
import WhyUsBullet from "./WhyUsBullet";
import BorderButton from "./BorderButton";

export default class WhyChooseUs extends Component {
  render() {
    return (
      <div className="container">
        <div className="container md:px-20 md:flex md:items-center md:justify-between">
          {/* Div to hold left side content */}
          {/* Hide it on mobile */}
          <div className="hidden md:inline-block">
            <div className="m-10 flex p-4 md:w-1/2 shadow-lg rounded-md">
              <img src={`${WhyChooseUsImage}`} alt="" />
            </div>
            <div className="p-5">
              <h2 className="text-xl md:text-3xl dark:text-white mb-4 font-bold text-left font-heading">
                <span>Start your </span>
                <span className="text-kushLight-primary">Project</span>
                <span> with us</span>{" "}
              </h2>

              <p className="dark:text-white mb-3">Ideas are worthless until execution, Let's talk and start your journey right away</p>
              <BorderButton title="Connect with us"></BorderButton>
            </div>
          </div>
          <div className="px-5 py-12 md:pt-32 text-center ">
            <div className="max-w-lg mx-auto mb-8">
              <h2 className="text-2xl md:text-4xl dark:text-white mb-4 font-bold text-right font-heading">
                <span>Here's why people choose </span>
                <span className="text-kushLight-primary">Kush Fintech</span>
                <br />
                <span> for their </span>{" "}
                <span className="text-kushLight-primary">Fin</span>
                <span>ancial and </span>
                <br />
                <span className="text-kushLight-primary inline-block">
                  Tech
                </span>
                <span>nological solution.</span>
              </h2>
              <p className="text-xs text-blueGray-400 dark:text-white text-right">
                You are treated as partner by us, and we work on to make your
                business journey successful from scratch whether it comes to
                finance or tech; we have your back.
              </p>
            </div>
            {/* Bullet points */}

            <WhyUsBullet
              title="From Idea to Solution"
              icon={<HiIcons.HiLightBulb></HiIcons.HiLightBulb>}
              styleClass="pb-2"
              size="text-3xl"
            ></WhyUsBullet>
            <WhyUsBullet
              title="100 % Satisfaction Guaranteed"
              icon={<MdIcons.MdSecurity></MdIcons.MdSecurity>}
              styleClass="pb-2 pr-1"
              size="text-2xl"
            ></WhyUsBullet>
            <WhyUsBullet
              title="24/7 Support"
              icon={
                <BsIcons.BsFillTelephoneInboundFill></BsIcons.BsFillTelephoneInboundFill>
              }
              styleClass="pb-2 pr-1"
              size="text-xl"
            ></WhyUsBullet>
          </div>

          {/* Div to hold left side content */}
          {/* Hide it on desktop */}
          <div className="md:hidden">
            <div className="m-10 flex p-4 md:w-1/2 shadow-lg rounded-md">
              <img src={`${WhyChooseUsImage}`} alt="" />
            </div>
            <div className="p-5">
              <h2 className="text-xl md:text-3xl dark:text-white mb-4 font-bold text-left font-heading">
                <span>Start your </span>
                <span className="text-kushLight-primary">Project</span>
                <span> with us</span>{" "}
              </h2>

              <p className="dark:text-white mb-3">Ideas are worthless until execution, Let's talk and start your journey right away</p>
              <BorderButton title="Connect with us"></BorderButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
