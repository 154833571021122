import React, { Component } from 'react'

export default class CallToAction extends Component {
    render() {
        return (
            <div className='m-auto my-12'>
                <h1 class="text-2xl font-bold text-transparent md:text-3xl bg-clip-text bg-gradient-to-r from-blue-700  to-fuchsia-500"> Have Projects in Mind? </h1>
            </div>
        )
    }
}
