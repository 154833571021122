import React, { Component } from "react";

export default class CounterGroup extends Component {
  render() {
    return (
      <div className="">
        <div className="bg-white dark:bg-kushDark-primary rounded-lg shadow-2xl w-counterGroupWidth p-4 md:p-0" >
          <div className="md:grid grid-cols-4 md:h-28 items-center divide-y-2 md:divide-y-0 md:divide-x-2 divide-kushLight-secondary text-black text-center dark:text-white ">
            <div className="py-4 md:py-0">
              <h1 className="text-xl md:text-2xl font-semibold">10+</h1>
              <p className="text-xs md:text-sm font-semibold">Clients</p>
            </div>
            <div className="py-4 md:py-0">
              <h1 className="text-xl md:text-2xl font-semibold">12+</h1>
              <p className="text-xs md:text-sm font-semibold">Projects</p>
            </div>
            <div className="py-4 md:py-0">
              <h1 className="text-xl md:text-2xl font-semibold">20+</h1>
              <p className="text-xs md:text-sm font-semibold">Teams</p>
            </div>
            <div className="py-4 md:py-0">
              <h1 className="text-xl md:text-2xl font-semibold">15+</h1>
              <p className="text-xs md:text-sm font-semibold">Services</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
