// import logo from './logo.svg';
import './App.css';
import Banner from './components/Banner';
import Clients from './components/Clients';
import WhyChooseUs from './components/WhyChooseUs';
import WorkingProcess from './components/working_process/WorkingProcess';
import ServicesRow from './components/financial_services/ServicesRow';
import { ServicesData } from "./components/data/ServicesData";
import Technologies from './components/Technologies';
// import TabsReact from './components/TabsReact';
import CounterSection from './components/counter/CounterSection';
import FeaturesSection from './components/FeaturesSection';
// import TestimonialSection from './components/testimonials/TestimonialSection';

function Home() {

  return (
    <div className="font-poppins">
      <Banner></Banner>
      <Clients></Clients>
      {/* <WhyChooseUs></WhyChooseUs> */}
      <WorkingProcess></WorkingProcess>
      {/* FinancialServices */}
      <ServicesRow title="Financial Services" subtitle="We provide best services to enhance your financial works." services={ServicesData.financialServices}/>
{/* FinancialServices */}
      <ServicesRow title="Our Core Technological Services" subtitle="We provide best services to enhance your technological works." services={ServicesData.techServices}/>
      {/* Technologies used */}
      <Technologies></Technologies>
      {/* <TabsReact></TabsReact> */}
      <FeaturesSection></FeaturesSection>

      {/* counter */}
      <CounterSection></CounterSection>

      {/* Testimonials */}
      {/* <TestimonialSection></TestimonialSection> */}
    </div>
  );
}

export default Home;
