import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class RoundedFilledButton extends Component {
    render() {
        return (
            <div className="md:flex items-center justify-center">
                <Link
                    className="block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-kushLight-primary hover:bg-kushLight-secondary dark:bg-kushDark-100 dark:hover:bg-kushDark-200 hover:transition hover:ease-in-out duration-300 rounded-3xl"
                    to={`/${this.props.link}`}
                >
                    {this.props.title}
                </Link>
            </div>
        )
    }
}
