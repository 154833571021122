import React from 'react';
import ContactTiles from './components/ContactTiles';
import ContactHero from './components/ContactHero';
import CallToAction from './components/CallToAction';

const Contact = ()=>{
        return (
            <div>
                <ContactHero/>
                <ContactTiles/>
                {/* <CallToAction></CallToAction> */}
            </div>
        )
}

export default Contact;