import React, { Component } from 'react';
import RoundedFilledButton from '../../widgets/RoundedFilledButton';
export default class TechnicalFeatures extends Component {
    render() {
        return (
            <div>
                {/* <span className="uppercase tracking-widest text-sm leading-4 mb-4 block text-center pt-10 text-gray-300"></span>
                <div className=''>
                    <h2 className="font-semibold text-2xl md:text-4xl xl:text-4xl leading-tight mb-4 text-center dark:text-white">Get In Touch With Us</h2>
                    <p className="leading-tight   text-center dark:text-white">We are available in most of the platform, follow us to get updated with us</p>
                </div> */}

                {/* Contact
                <section className='container'>
                    <div className='grid grid-cols-1 md:grid-cols-4 m-4 contact-card-container'>
                        <div className='col-span-1 shadow-lg m-4 p-4 rounded-md'>
                            <p>This is heading</p>
                            <p>This is description</p>
                        </div>
                        <div className='col-span-1 shadow-lg'>
                            <p>This is heading</p>
                            <p>This is description</p>
                        </div>
                        <div className='col-span-1 shadow-lg'>
                            <p>This is heading</p>
                            <p>This is description</p>
                        </div>
                        <div className='col-span-1 shadow-lg'>
                            <p>This is heading</p>
                            <p>This is description</p>
                        </div>
                    </div>
                </section> */}

                {/* Contact  */}

                <section class="py-20 bg-white dark:bg-kushDark-100">
                    <div class="container max-w-6xl mx-auto">
                        <h2 class="text-4xl font-bold tracking-tight text-center dark:text-white">Get in Contact with Us</h2>
                        <p class="mt-2 text-lg text-center text-gray-600 dark:text-white">We are available in most of the platform, follow us to get updated with us</p>
                        <div class="grid grid-cols-4 gap-8 mt-10 sm:grid-cols-8 lg:grid-cols-12 sm:px-8 xl:px-0">

                            <div class="relative flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 overflow-hidden bg-gray-100 rounded-md">
                                <div class="p-3 text-white bg-purple-700 rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 " viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M14 3v4a1 1 0 0 0 1 1h4"></path><path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5"></path><circle cx="6" cy="14" r="3"></circle><path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5"></path></svg>
                                </div>
                                <h4 class="text-xl font-medium text-gray-700">Phone</h4>
                                <p class="text-base text-center text-gray-500"><a href="tel:+977-9818821313">+977-9818821313</a></p>
                            </div>

                            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 rounded-md">
                                <div class="p-3 text-white bg-purple-700 rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 " viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M18 8a3 3 0 0 1 0 6"></path><path d="M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5"></path><path d="M12 8h0l4.524 -3.77a0.9 .9 0 0 1 1.476 .692v12.156a0.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8"></path></svg>
                                </div>
                                <h4 class="text-xl font-medium text-gray-700">Email</h4>
                                <p class="text-base text-center text-gray-500"><a href="mailto:info@kushfintech.com">info@kushfintech.com</a></p>
                            </div>

                            <div class="flex flex-col items-center justify-between col-span-4 px-8 py-12 space-y-4 bg-gray-100 rounded-md">
                                <div class="p-3 text-white bg-purple-700 rounded-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 " viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline><line x1="12" y1="12" x2="20" y2="7.5"></line><line x1="12" y1="12" x2="12" y2="21"></line><line x1="12" y1="12" x2="4" y2="7.5"></line><line x1="16" y1="5.25" x2="8" y2="9.75"></line></svg>
                                </div>
                                <h4 class="text-xl font-medium text-gray-700">Address</h4>
                                <p class="text-base text-center text-gray-500">Mid Baneshwor, Kathmandu</p>
                            </div>

                           
                        </div>
                    </div>
                </section>


                <div>

                </div>
            </div>
        )
    }
}
