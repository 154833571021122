import React, { Component } from 'react';
import RoundedFilledButton from '../../widgets/RoundedFilledButton';
export default class TechnicalFeatures extends Component {
    render() {
        return (
            <div>
                <span className="uppercase tracking-widest text-sm leading-4 mb-4 block text-center pt-10 text-gray-300">New-in</span>
                <h2 className="font-heading text-7xl md:text-8xl xl:text-9xl leading-tight mb-28 2xl:mb-24 text-center dark:text-white">Our Core Tech Services</h2>
                {/* Card glass morphism */}
                <div className='bg-kushLight-secondary dark:bg-kushDark-100'>
                    <div className="technicalservicecontainer">
                        <div className='grid grid-cols-1'>
                            <div className='grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2'>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Web App Development</h1>
                                    <p className="technicalservicesubtitle dark:text-black">Develop Web app that feels unique from each angle and provides smooth user experience</p>
                                    {/* <RoundedFilledButton title="View More" link="web-development" /> */}

                                </div>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Mobile App Development</h1>
                                    <p className="technicalservicesubtitle dark:text-black">A mobile app that will boost your business, know how we work to develop your next successful application.</p>
                                    {/* <RoundedFilledButton title="View More" link="app-development" /> */}
                                </div>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Cloud Computing</h1>
                                    <p className="technicalservicesubtitle dark:text-black">Keeping your online presence all the time is most important, and here we provide robust cloud services</p>
                                    {/* <RoundedFilledButton title="View More" link="web-development" /> */}

                                </div>


                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2'>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Digital Marketing</h1>
                                    <p className="technicalservicesubtitle dark:text-black">It's not only about digital presence, it more about how much you are visible to your audience</p>
                                    {/* <RoundedFilledButton title="View More" link="digital-marketing" /> */}

                                </div>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Graphic Designing</h1>
                                    <p className="technicalservicesubtitle dark:text-black">Graphics are only the way you can get the vibe of the product.</p>
                                    {/* <RoundedFilledButton title="View More" link="graphic-designing" /> */}

                                </div>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Video Editing</h1>
                                    <p className="technicalservicesubtitle dark:text-black">We provide the best video post production offering high quality professional video editing services unique to your business. </p>
                                    {/* <RoundedFilledButton title="View More" link="web-development" /> */}

                                </div>
                                

                            </div>
                        </div>
                        <div className="technicalserviceblob"></div>
                    </div>
                </div>
                {/* Card glassmorphism end */}
                <div>

                </div>
            </div>
        )
    }
}
