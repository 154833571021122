import React, { Component } from "react";
// import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

class ServicesCard extends Component {
  render(props) {
    return (
      <div className="my-8">
        {/* Card outer layer */}
        <div>
          {/* border-kushDark-200 border-opacity-20 border-2 ======== to add border */}
          <div className=" dark:text-white h-servicesCardHeight m-4 md:w-6/6  rounded-lg shadow-md hover:shadow-xl transition duration-500 hover:-translate-y-2">
            {/* Image section */}
            <div className="overflow-hidden bg-clip-border rounded-lg">
              <img src={this.props.services.image} alt={this.props.services.image +" image"} className="object-cover" />
            </div>
            {/* Text Section */}
            <div className="p-4 text-center ">
            <h1 className="text-xl font-bold py-4">{this.props.services.title}</h1>
            <p className="text-sm">{this.props.services.description}</p>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default ServicesCard;


// import React, { Component } from "react";
// // import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

// class ServicesCard extends Component {
//   render(props) {
//     return (
//       // flip-x-reverse
      
//       <div className="my-8 mx-8 h-96 w-96 transition-all  transform-gpu cursor-pointer">
//         <div className="relative w-full h-full kush-rotate-y-180 duration-1000">
//           <div className="absolute w-full h-full bg-black">
            
//           </div>
//           <div className="absolute w-full h-full bg-red-900">

//           </div>
//         </div>
//       </div>
//     );

//   }
// }

// export default ServicesCard;
