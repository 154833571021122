import React, { Component } from "react";
import RoundedButton from "../widgets/RoundedButton";
import CounterGroup from "./CounterGroup";

export default class CounterSection extends Component {
  render() {
    return (
      <div className=" bg-kushLight-secondary text-white h-counterSectionHeight my-72 md:my-40">
        <div className="container md:px-20 md:flex md:items-center md:justify-between">
          <div>
            {/* sologan */}
            <div className="pt-10 px-4">
              <p className="font-semibold text-sm">
                Helping you grow and nurture your ideas
              </p>
            </div>
            {/* our work your satisfaction */}
            <div className="px-4 py-4 text-3xl font-bold space-y-1">
              <h1>Our Work,</h1>
              <h1>Your Satisfaction</h1>
            </div>

            <div className=" px-4">
              <p className="font-semibold text-sm">
                Have an idea? Connect with us and make <br /> an impactful
                journey.
              </p>
            </div>

            {/* Button */}
            <div className="py-4 px-4">
              <RoundedButton title="Contact us now"></RoundedButton>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <CounterGroup></CounterGroup>
        </div>
      </div>
    );
  }
}
