import React from 'react';
import TechnicalHeroImage from "../../../assets/img/images/SVG/technicalhero.svg";
import { Link } from 'react-router-dom';
import { ThemeContext } from "../../ThemeContext";


export default function TechnicalHero() {
  const { theme } = React.useContext(ThemeContext);
  return (
    <div>

      <div className="">
        <div className="inner-header">
          {/* Content start */}
          <div className="container flex items-center justify-center pt-12 md:pt-20">

            <div className="container md:px-20 md:flex md:items-center md:justify-between">
              <div className="px-5 py-12 md:pt-10">

                {/* <div className="max-w-lg mx-auto mb-8">
                  <h2 className="text-2xl md:text-6xl space-y-4 dark:text-white my-8 font-semibold text-left font-heading">
                    <span>Contact Us </span>
                    <br />
                    
                  </h2>
                  <p className="text-xs md:text-base text-blueGray-400 dark:text-white text-left">
                    Feel free to reach us 24*7, we are available for you anytime.

                  </p>
                </div> */}
               
              </div>

              
            </div>
          </div>

          {/* Content end */}
        </div>

      </div>
    </div>
  )
}
