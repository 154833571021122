import React, { Component } from 'react';

export default class RoundedButton extends Component {
    render() {
        return (
            <div>
                <a
                  className="mr-2 inline-block px-4 py-3 text-xs dark:text-white text-white hover:text-white font-semibold leading-none border border-white hover:border-blue-300 rounded-2xl"
                  href="/"
                >
                  {this.props.title}
                </a>
            </div>
        )
    }
}
