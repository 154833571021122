import React, { Component } from 'react'
import SectionTitle from '../widgets/SectionTitle';
import ServicesCard from '../widgets/ServicesCard';


export default class ServicesRow extends Component {
    render(props) {
        return (
            <div className={`${this.props.backgroundClass}`}>
                <div className="container md:px-20 md:py-10">
                    <SectionTitle title={this.props.title} description={this.props.subtitle} />
                    {/* Card row */}
                    <div className="md:grid md:grid-cols-3 overflow-x-auto">
                        {this.props.services.map((service) => {
                            return <ServicesCard services={service} />
                        })}
                    </div>

                    {/* <div className="flex">
                        {this.props.services.map((service) => {
                            return <ServicesCard services={service} />
                        })}
                    </div> */}




                </div>
            </div>
        )
    }
}
