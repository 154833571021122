import React, { Component } from 'react'

export default class SectionTitle extends Component {
    render(props) {
        return (
            <div className="text-center md:my-3">
                <div className="text-4xl md:text-3xl text-kushLight-secondary dark:text-white font-heading font-bold">
                <h1>{this.props.title}</h1>
                </div>

                <div>
                    <p className="dark:text-white pt-2">{this.props.description}</p>
                </div>
            </div>
        )
    }
}
