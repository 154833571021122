import React, { useState } from "react";
import Logo from "../assets/img/logo/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import ToggleMode from "./ToggleMode";
import BorderButton from "./BorderButton";
import { motion } from "framer-motion/dist/framer-motion";

import {
  Link
} from "react-router-dom";


const Navbar = () => {
  const [isMenuOpen, setisMenuOpen] = useState(false);


  let svgVariants = {
    hidden: {
      x: "-5vh",
    },
    visible: {
      x: 0,
      transition: {
        duration: 1,
        ease: "easeInOut",
        type: "spring"
      }
    }
  }
  let path1Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 0.3

      }
    }
  }
  let path2Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 0.6

      }
    }
  }
  let path3Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 0.9

      }
    }
  }
  let path4Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 1.2

      }
    }
  }
  let path5Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 1.5

      }
    }
  }
  let path6Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 1.8

      }
    }
  }
  let path7Variants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        delay: 2.1

      }
    }
  }
  let kVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: 0.5

      }
    }
  }
  let uVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: 1

      }
    }
  }
  let sVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: 1.5

      }
    }
  }
  let hVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: 2

      }
    }
  }

  let underlineVariants = {
    hidden: {
      opacity: 0,
      pathLength: 0
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
        delay: 2.5

      }
    }
  }

  let navbarVariants = {
    hidden: {
      y: "-15px",
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
        dely: 0.1
      }
    }
  }
  let darkModeButtonVariants = {
    hidden: {
      x: "100vh",
      opacity: 0
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: "easeInOut",
        dely: 0.1,
        type: "spring"
      }
    }
  }

  let LogoSVG = (<div className="h-16 w-24 m-auto">
    <motion.svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 728.99"
      variants={svgVariants}
      initial="hidden"
      animate="visible"
    >
      <defs>

        <linearGradient id="Degradado_sin_nombre_950" x1="428.42" y1="609.34" x2="776.02" y2="609.34"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#46edff" />
          <stop offset="0.31" stop-color="#247aff" />
          <stop offset="0.51" stop-color="#9b46ff" />
          <stop offset="0.74" stop-color="#f441ff" />
          <stop offset="1" stop-color="#ffa9ff" />
        </linearGradient>
        <linearGradient id="Degradado_sin_nombre_926" x1="2541.32" y1="2732.14" x2="2922.63" y2="2350.83"
          gradientTransform="matrix(-0.71, -0.71, -0.71, 0.71, 4865.53, 404.32)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#6418b0" />
          <stop offset="0.01" stop-color="#661ab3" />
          <stop offset="0.08" stop-color="#792acf" />
          <stop offset="0.16" stop-color="#8836e4" />
          <stop offset="0.25" stop-color="#933ff3" />
          <stop offset="0.35" stop-color="#9944fc" />
          <stop offset="0.5" stop-color="#9b46ff" />
          <stop offset="1" stop-color="#b880ff" />
        </linearGradient>
        <linearGradient id="linear-gradient" x1="-7450.67" y1="533.14" x2="-7641.14" y2="342.67"
          gradientTransform="matrix(-1, 0, 0, 1, -6702.52, 0.08)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#ffa9ff" />
          <stop offset="0.28" stop-color="#ff67ff" />
          <stop offset="0.48" stop-color="#ff3dff" />
          <stop offset="0.58" stop-color="#ff2dff" />
          <stop offset="0.68" stop-color="#f928fa" />
          <stop offset="0.81" stop-color="#e81bea" />
          <stop offset="0.96" stop-color="#cb06d1" />
          <stop offset="1" stop-color="#c300ca" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="837.25" y1="151.75" x2="1028.25" y2="342.74"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#8127ff" />
          <stop offset="0.43" stop-color="#bd37ff" />
          <stop offset="0.63" stop-color="#cd3aff" />
          <stop offset="1" stop-color="#f441ff" />
        </linearGradient>
        <linearGradient id="Degradado_sin_nombre_926-2" x1="455.93" y1="443.45" x2="837.23" y2="62.15"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)" xlinkHref="#Degradado_sin_nombre_926" />
        <linearGradient id="linear-gradient-3" x1="176.06" y1="5154.89" x2="456.44" y2="4874.51"
          gradientTransform="matrix(1, 0, 0, -1, 0, 5407.36)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#3597ff" />
          <stop offset="0.58" stop-color="#4c53de" />
          <stop offset="0.73" stop-color="#4d4edb" />
          <stop offset="0.9" stop-color="#5140d1" />
          <stop offset="1" stop-color="#5434ca" />
        </linearGradient>
        <linearGradient id="linear-gradient-4" x1="265.45" y1="5154.67" x2="455.93" y2="5345.14"
          gradientTransform="matrix(1, 0, 0, -1, 0, 5407.36)" gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#2b55e3" />
          <stop offset="0.27" stop-color="#247aff" />
          <stop offset="1" stop-color="#46edff" />
        </linearGradient>
        <linearGradient id="linear-gradient-5" x1="8.44" y1="207.25" x2="389.74" y2="-174.05"
          gradientTransform="translate(-86.69 395) rotate(-44)" xlinkHref="#Degradado_sin_nombre_926" />
        <linearGradient id="linear-gradient-6" x1="145.83" y1="723.57" x2="1058.47" y2="723.57"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color="#46edff" />
          <stop offset="0.26" stop-color="#247aff" />
          <stop offset="0.52" stop-color="#9b46ff" />
          <stop offset="0.79" stop-color="#f441ff" />
          <stop offset="1" stop-color="#ffa9ff" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="KUSH">
          <g id="KUSH-2" data-name="KUSH">
            <motion.path variants={kVariants} initial="hidden" animate="visible" class="cls-2"
              d="M476.78,651.23l-28-36.84v36.84H428.42V567.8h20.32v36.61l27.81-36.61h23.88l-32.32,40.89,33.51,42.54Z" />
            <motion.path variants={uVariants} initial="hidden" animate="visible" class="cls-2"
              d="M543.1,567.8v49.92q0,7.49,3.68,11.53t10.81,4q7.14,0,10.94-4t3.8-11.53V567.8h20.32v49.8q0,11.17-4.75,18.89a30.36,30.36,0,0,1-12.78,11.65,40.07,40.07,0,0,1-17.88,3.92,39.11,39.11,0,0,1-17.65-3.86,28.76,28.76,0,0,1-12.3-11.65q-4.51-7.77-4.52-18.95V567.8Z" />
            <motion.path variants={sVariants} initial="hidden" animate="visible" class="cls-2"
              d="M632.58,649.09A26.81,26.81,0,0,1,621,640.3a24.05,24.05,0,0,1-4.57-14h21.63a10.47,10.47,0,0,0,3.21,7.08,10.33,10.33,0,0,0,7.13,2.43,11.14,11.14,0,0,0,7.13-2.08,7,7,0,0,0,2.61-5.76,6.81,6.81,0,0,0-2.08-5.11,16.62,16.62,0,0,0-5.11-3.33,82.87,82.87,0,0,0-8.61-3,94,94,0,0,1-13.2-5,24.5,24.5,0,0,1-8.79-7.37q-3.69-4.87-3.68-12.72,0-11.64,8.43-18.24t22-6.59q13.78,0,22.22,6.59t9,18.36h-22a8.63,8.63,0,0,0-3-6.36,10.48,10.48,0,0,0-7-2.31,8.75,8.75,0,0,0-6,2,7.09,7.09,0,0,0-2.25,5.64,7,7,0,0,0,3.8,6.3,61.86,61.86,0,0,0,11.88,4.87A112.1,112.1,0,0,1,667,606.9a25.14,25.14,0,0,1,8.73,7.25q3.69,4.76,3.69,12.24a24,24,0,0,1-3.63,13,25.42,25.42,0,0,1-10.52,9.27A36,36,0,0,1,649,652.06,42.92,42.92,0,0,1,632.58,649.09Z" />
            <motion.path variants={hVariants} initial="hidden" animate="visible" class="cls-2"
              d="M776,567.8v83.43H755.7V616.89H724.09v34.34H703.77V567.8h20.32v32.69H755.7V567.8Z" />
          </g>
        </g>
        <motion.g id="_7" data-name="7"
          variants={path7Variants}
        >
          <polygon id="_7-2" data-name="7" class="cls-3"
            points="1199.68 126.73 1200 126.63 1200 539.05 1073.27 539.24 1073.27 0 1199.68 126.73" />
        </motion.g>
        <motion.g id="_6" data-name="6"
          variants={path6Variants}
        >
          <polygon id="_6-2" data-name="6" class="cls-4"
            points="983.29 387.69 882.56 488.41 703.34 488.41 893.83 297.95 983.29 387.69" />
        </motion.g>
        <motion.g id="_5" data-name="5"
          variants={path5Variants}
        >
          <polygon id="_5-2" data-name="5" class="cls-5"
            points="1073.27 297.72 882.49 106.95 882.04 106.95 792.52 196.48 983.22 387.77 1073.27 297.72" />
        </motion.g>
        <motion.g id="_4" data-name="4"
          variants={path4Variants}
        >
          <polygon id="_4-2" data-name="4" class="cls-6"
            points="703.04 107.18 702.89 106.88 411.26 398.51 500.74 488.26 882.04 106.95 703.04 107.18" />
        </motion.g>
        <motion.g id="_3" data-name="3"
          variants={path3Variants}
        >
          <polygon id="_3-2" data-name="3" class="cls-7"
            points="321.81 488.26 131.03 297.49 221.08 207.45 501.04 488.26 321.81 488.26" />
        </motion.g>
        <motion.g
          variants={path2Variants}
          id="_2" data-name="2">
          <polygon id="_2-2" data-name="2" class="cls-8"
            points="220.78 207.75 321.51 107.03 500.74 107.03 310.25 297.49 220.78 207.75" />
        </motion.g>
        <motion.g
          id="_1" data-name="1"
          variants={path1Variants}
          initial="hidden"
          animate="visible"
        >
          <polygon id="_1-2" data-name="1" class="cls-9"
            points="7.51 124.67 7.2 124.56 0 536.92 126.71 539.33 136.12 0.17 7.51 124.67" />
        </motion.g>
        <motion.g id="Underline"
          variants={underlineVariants}
        >
          <rect id="Underline-2" data-name="Underline" class="cls-10" x="145.83" y="718.15" width="912.64"
            height="10.84" />
        </motion.g>
      </g>
    </motion.svg>
  </div>)

  return (

    <div className="fixed mb-20 container float-right max-w-none bg-white z-50 dark:bg-kushDark-primary">
      <section
        className="xl:bg-contain bg-top bg-no-repeat"
      //   style="background-image: url('metis-assets/backgrounds/intersect.svg');"
      >

        <div className="container px-4 mx-auto">
          <nav className="flex justify-between items-center pt-2">
            <Link className="text-3xl font-semibold leading-none" to="/">
              {LogoSVG}
              {/* <img className="h-16" src={`${Logo}`} alt="" width="auto" /> */}
            </Link>



            <motion.ul className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12"
              variants={navbarVariants} initial="hidden" animate="visible"
            >

              {/* <li>
                <Link
                  className="text-sm dark:text-white text-blueGray-400 hover:text-blueGray-500"
                  to="/"
                >
                  About Us
                </Link>
              </li> */}
              <li>
                <Link
                  className="text-sm dark:text-white text-blueGray-400 hover:text-blueGray-500"
                  to="/financial-services"
                >
                  Financial Services
                </Link>
              </li>
              <li>
                <Link
                  className="text-sm dark:text-white text-blueGray-400 hover:text-blueGray-500"
                  to="/technical-services"
                >
                  Tech Services
                </Link>
              </li>
              {/* <li>
                <Link
                  className="text-sm dark:text-white text-blueGray-400 hover:text-blueGray-500"
                  to="/"
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  className="text-sm dark:text-white text-blueGray-400 hover:text-blueGray-500"
                  to="/"
                >
                  Methodology
                </Link>
              </li> */}
              <li>
                <BorderButton to='contact' title="Connect with us"></BorderButton>
              </li>
            </motion.ul>

            <div className=" flex justify-center items-center">
              <div className="lg:hidden">
                <button
                  onClick={() => {
                    setisMenuOpen(true)
                  }}
                  className="flex justify-end items-end ml-36 py-2 px-3 text-kushLight-primary hover:text-blue-700 rounded border border-blue-200 hover:border-blue-300"
                >
                  <FontAwesomeIcon icon={faBars} />
                </button>
              </div>
              <motion.div variants={darkModeButtonVariants} initial="hidden" animate="visible">
                <ToggleMode></ToggleMode>
              </motion.div>

              {isMenuOpen ? (
                <div>
                  <div className="navbar-menu shadow-xl fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
                    <div className="navbar-backdrop fixed inset-0 bg-blueGray-800 opacity-25"></div>
                    <nav className="relative flex flex-col py-2 px-4 w-full h-full dark:bg-kushDark-primary bg-white shadow-2xl overflow-y-auto">
                      <div className="flex items-center mb-8">
                        <Link
                          className="mr-auto text-3xl font-semibold leading-none"
                          to="/"
                        >
                          <img
                            className="h-16"
                            src={`${Logo}`}
                            alt=""
                            width="auto"
                          />
                        </Link>
                        <button
                          onClick={() => {
                            setisMenuOpen(false);
                          }}
                          className="navbar-close dark:text-white"
                        >
                          <svg
                            className="h-6 w-6 text-blueGray-400 cursor-pointer hover:text-blueGray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div>
                        <ul>
                          {/* <li className="mb-1">
                            <Link
                              className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 dark:text-white hover:text-blue-600"
                              to="/"
                            >
                              About Us
                            </Link>
                          </li> */}
                          <li className="mb-1">
                            <Link
                              className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 dark:text-white hover:text-blue-600"
                              to="/financial-services"
                            >
                              Financial Services
                            </Link>
                          </li>
                          <li className="mb-1">
                            <Link
                              className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 dark:text-white hover:text-blue-600"
                              to="/technical-services"
                            >
                              Tech Services
                            </Link>
                          </li>
                          {/* <li className="mb-1">
                            <Link
                              className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 dark:text-white hover:text-blue-600"
                              to="/projects"
                            >
                              Projects
                            </Link>
                          </li>
                          <li className="mb-1">
                            <Link
                              className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 dark:text-white hover:text-blue-600"
                              to="/methodology"
                            >
                              Methodology
                            </Link>
                          </li> */}
                        </ul>
                        <div className="mt-4 pt-6 border-t border-blueGray-100">
                          <BorderButton to="/contact" title="Connect with us"></BorderButton>
                          {/* <a
                              className="block px-4 py-3 mb-3 text-xs text-center font-semibold leading-none dark:text-white bg-blue-600 hover:bg-blue-700 text-white rounded"
                              href="/"
                            >
                              Sign Up
                            </a>
                            <a
                              className="block px-4 py-3 mb-2 text-xs text-center text-blue-600 hover:text-blue-700 font-semibold leading-none border border-blue-200 hover:border-blue-300 rounded"
                              href="/"
                            >
                              Log In
                            </a> */}
                        </div>
                      </div>
                      <div className="mt-auto">
                        <p className="my-4 dark:text-white text-xs text-blueGray-400">
                          <span>Get in Touch </span>
                          <a
                            className="text-kushLight-primary hover:text-blue-600 underline"
                            href="/"
                          >
                            info@kushfintech.com
                          </a>
                        </p>
                        <a className="inline-block px-1" href="/">
                          <img
                            src="metis-assets/icons/facebook-blue.svg"
                            alt=""
                          />
                        </a>
                        <a className="inline-block px-1" href="/">
                          <img
                            src="metis-assets/icons/twitter-blue.svg"
                            alt=""
                          />
                        </a>
                        <a className="inline-block px-1" href="/">
                          <img
                            src="metis-assets/icons/instagram-blue.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </nav>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </nav>
        </div>
      </section>
    </div>
  );

}

export default Navbar;