// import { useState } from "react";
import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import SectionTitle from "./widgets/SectionTitle";

import technologiesUsed from "./data/TechnologiesUsed";




export default function Technologies() {
  return (
    <div className="">
      <SectionTitle
        title="Technologies we expertise with"
        description="We use robust technologies to provide you scalable services"
      ></SectionTitle>

      <div className="container md:px-20">
        <div className="w-full px-2 py-8 my-4 sm:px-0">
          <Tab.Group defaultIndex={0}>
            <Tab.List className="flex-auto text-center p-1 space-x-8  rounded-xl">
              {technologiesUsed.map((category, i) => (
                <Tab key={i}  
                className={ ({selected}) =>` ${selected && " text-kushLight-primary dark:text-kushLight-primary transition-colors duration-100 "} dark:text-white  hover:text-kushLight-600 font-semibold space-x-4 px-6  m-auto`}
            >
              {category.categoryName}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {technologiesUsed.map((category, index) => (
                <Tab.Panel
                  key={index}
                >
                  {/* Start */}
                  <Tab.Group>
                    <Tab.List className="flex-auto m-auto text-center ">
                    
                      {category.subCategories.map((subCategory, i) => (
                        <Tab key={i} className={ ({selected}) =>`  dark:text-white col-span-1 hover:text-kushLight-600 font-medium space-x-4 px-6 my-8  m-auto ${selected && " text-kushLight-primary dark:text-kushLight-primary transition-colors duration-100 "}` }
                        >
                          {subCategory.subCategoryName}
                        </Tab>
                      ))}
                      
                    </Tab.List>
                    <Tab.Panels>
                      {category.subCategories.map((subCategory, j) => (
                        // make grid of cards
                        <Tab.Panel className="grid grid-cols-2 md:grid-cols-4 overflow-x-auto  text-center justify-center " key={j}>
                          {subCategory.items.map((item, j) => {
                            return <div className="shadow-lg p-4 rounded-md m-10">
                              <img className="mb-4" src={item.icon} alt={item.name} />
                             
                              <p className="text-base font-semibold dark:text-white text-kushLight-secondary  relative">{item.name}</p>

                            </div>
                          })}
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                  {/* End */}
                </Tab.Panel>
              ))}

            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}
