import React, { Component } from 'react';
import TechnicalFeatures from './features/TechnicalFeatures';
import TechnicalHero from './components/TechnicalHero';
import CallToAction from './components/CallToAction';

export default class Technical extends Component {
    render() {
        return (
            <div>
                <TechnicalHero/>
                <TechnicalFeatures/>
                {/* <CallToAction></CallToAction> */}
            </div>
        )
    }
}
