import React from 'react';
import TechnicalHeroImage from "../../../assets/img/images/SVG/technicalhero.svg";
import { Link } from 'react-router-dom';
import { ThemeContext } from "../../ThemeContext";


export default function TechnicalHero() {
  const { theme } = React.useContext(ThemeContext);
  return (
    <div>

      <div className="wavesheader">
        <div className="inner-header wavesflex">
          {/* Content start */}
          <div className="container flex items-center justify-center pt-12 md:pt-20">

            <div className="container md:px-20 md:flex md:items-center md:justify-between">
              <div className="px-5 py-12 md:pt-10">

                <div className="max-w-lg mx-auto mb-8">
                  <h2 className="text-2xl md:text-6xl space-y-4 dark:text-white mb-4 font-bold text-left font-heading">
                    <span>We Work </span>
                    <br />
                    <span className="text-kushLight-primary inline-block">Your Ideas</span>
                  </h2>
                  <p className="text-xs md:text-base text-blueGray-400 dark:text-white text-left">
                    Technology is the new business model. It’s the new way of solving problems,
                    and It’s the new way of thinking. It’s the new way of doing business.

                  </p>
                </div>
                <div className="md:flex">
                  <Link
                    className="block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-kushLight-primary hover:bg-blue-700 rounded"
                    to="/contact"
                  >
                    Contact Us Now
                  </Link>

                </div>
              </div>

              <div className="h-1/3 md:1/2 m-10 mt-10 flex md:w-6/12 rounded-md">
                <img src={`${TechnicalHeroImage}`} alt="" />
              </div>
            </div>
          </div>

          {/* Content end */}
        </div>


        {theme === "dark" ? (<div>
    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="wavesparallax">
        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(25,25,25,0.7" />
        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(25,25,25,0.5)" />
        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(25,25,25,0.3)" />
        <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(25, 25, 25)" />
      </g>
    </svg>
  </div>) : (<div>
      <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g className="wavesparallax">
          <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>)}
      </div>
    </div>
  )
}
