import React, { Component } from "react";

import eduvibeLogo from "../assets/img/logo/eduvibe_logo.png";
import logikLogo from "../assets/img/logo/logik_logo.png";
import nepalaupairLogo from "../assets/img/logo/nepalaupair_logo.png";
import nfaLogo from "../assets/img/logo/nfa_logo.png";
import itfLogo from "../assets/img/logo/itf_logo.png";

export default class Clients extends Component {
  render() {
    return (
      <div className="mt-5  bg-kushLight-100 dark:bg-kushDark-100">
        <div className="flex flex-wrap items-center justify-center max-w-4xl mx-auto pt-8">
          <div className="w-1/4 md:w-1/3 lg:w-1/6 px-4 mx-2 mb-8">
            <img className="mx-auto" src={`${eduvibeLogo}`} alt="" />
          </div>
          <div className="w-1/4 md:w-1/3 lg:w-1/5 px-4 mx-2 mb-8">
            <img className="mx-auto" src={`${nfaLogo}`} alt="" />
          </div>
          <div className="w-1/3 md:w-1/3 lg:w-1/4 px-4 mx-2 mb-8">
            <img className="mx-auto" src={`${logikLogo}`} alt="" />
          </div>
          <div className="w-1/5 md:w-1/3 lg:w-32 px-4 mx-2 mb-8">
            <img className="mx-auto" src={`${nepalaupairLogo}`} alt="" />
          </div>
          <div className="w-1/5 md:w-1/3 lg:w-32 px-4 mx-2 mb-8">
            <img className="mx-auto" src={`${itfLogo}`} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
