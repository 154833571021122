import React, { Component } from 'react'
import WorkingProcessItem from './WorkingProcessItem'
import SectionTitle from '../widgets/SectionTitle'


export default class WorkingProcess extends Component {
    render() {
        return (
            <div>
                {/* Working process */}
                <div className="container  md:px-20 mt-10 md:mt-16">
                    <SectionTitle title="Working Process" description="We use agile methodology." />

                    <div className="md:grid md:grid-cols-3 justify-around pb-20">
                    <WorkingProcessItem count="1" title="Understand Requirements" subtitle="Ideas are worthless"></WorkingProcessItem>
                    <WorkingProcessItem count="2" title="Research & Analysis" subtitle="Ideas are worthless"></WorkingProcessItem>
                    <WorkingProcessItem count="3" title="Build Solutions" subtitle="Ideas are worthless"></WorkingProcessItem>
                    </div>
                </div>
            </div>
        )
    }
}
