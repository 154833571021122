import React, { Component } from 'react';
import RoundedFilledButton from '../../widgets/RoundedFilledButton';
export default class FinancialFeatures extends Component {
    render() {
        return (
            <div>
                <span className="uppercase tracking-widest text-sm leading-4 mb-4 block text-center pt-10 text-gray-300">New-in</span>
                <h2 className="font-heading text-7xl md:text-8xl xl:text-9xl leading-tight mb-28 2xl:mb-24 text-center dark:text-white">Our Financial Services</h2>
                {/* Card glass morphism */}
                <div className='bg-kushLight-secondary dark:bg-kushDark-100'>
                    <div className="technicalservicecontainer">
                        <div className='grid grid-cols-1'>
                            <div className='grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2'>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Financial Projection</h1>
                                    <p className="technicalservicesubtitle dark:text-black">We help you project the expected revenues, expenses, and cash flows of a business over a forecast period.  </p>
                                    {/* <RoundedFilledButton title="View More" link="web-development" /> */}

                                </div>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Business Consulting</h1>
                                    <p className="technicalservicesubtitle dark:text-black">Our Business Consulting Services are structured to address a company's end-to-end business needs.</p>
                                    {/* <RoundedFilledButton title="View More" link="app-development" /> */}
                                </div>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Startup Establishment</h1>
                                    <p className="technicalservicesubtitle dark:text-black">When planning to expand your startup, choosing the right partner to tackle the linguistic aspect of the process is crucial.</p>
                                    {/* <RoundedFilledButton title="View More" link="web-development" /> */}

                                </div>


                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2'>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Accounting</h1>
                                    <p className="technicalservicesubtitle dark:text-black">We offer a broad spectrum of accounting, financial, and consulting services across a number of industries to give business owners and managers the insight they need to prosper.</p>
                                    {/* <RoundedFilledButton title="View More" link="digital-marketing" /> */}

                                </div>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Tax Consulting</h1>
                                    <p className="technicalservicesubtitle dark:text-black">We’re a new generation of tax consultants who bring strategy, technology, compliance and tax advisory together to identify where businesses and organisations need to be, then get them there</p>
                                    {/* <RoundedFilledButton title="View More" link="graphic-designing" /> */}

                                </div>
                                <div className="technicalservicecard">
                                    <h1 className="technicalservicetitle dark:text-black">Legal Compliance</h1>
                                    <p className="technicalservicesubtitle dark:text-black">We provide bespoke regulatory compliance and risk management and advice to firms.</p>
                                    {/* <RoundedFilledButton title="View More" link="web-development" /> */}

                                </div>
                                

                            </div>
                        </div>
                        <div className="technicalserviceblob"></div>
                    </div>
                </div>
                {/* Card glassmorphism end */}
                <div>

                </div>
            </div>
        )
    }
}
