import React, { Component } from "react";
import KushLogo from "../assets/img/logo/logo.png"
import * as HiIcon from "react-icons/hi";
import * as FaIcon from "react-icons/fa";


export default class Footer extends Component {
  render() {
    return (
      <div>
        <div className="relative mt-16 bg-gray-100 dark:bg-kushDark-100">
          <svg
            className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-gray-100 dark:text-kushDark-100"
            preserveAspectRatio="none"
            viewBox="0 0 1440 54"
          >
            <path
              fill="currentColor"
              d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
            />
          </svg>
          <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 text-gray-900 dark:text-white">
            <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-5">
              <div className="md:max-w-md lg:col-span-2 text-center md:text-left">
                <a
                  href="/"
                  aria-label="Go home"
                  title="Company"
                  className="inline-flex items-center"
                >
                  <div className="w-32">
                  <img src={KushLogo} alt="Kush Fintech Logo" />
                  </div>
                </a>
                <div className="mt-4 lg:max-w-sm">
                  <p className="text-sm text-deep-purple-50">
                    KUSH Fintech Consultants was started a year ago with the motive of 
                    serving businesses in both finance and technology related services.
                  </p>
                  <p className="mt-4 text-sm text-deep-purple-50">
                  < HiIcon.HiOutlinePhoneOutgoing className="inline text-lg"/> <a href="tel:+9779818821313">+977 98188-21313</a>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-3 md:grid-cols-3">
               
                <div className="text-sm">
                  <p className="font-bold tracking-wide text-base">
                    Financial Services
                  </p>
                  <ul className="mt-2 space-y-2">
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Financial Projection
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Business Consulting
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Startup Establishment
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Accounting
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Tax Consulting
                      </a>
                    </li> <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                       Legal Compliance
                      </a>
                    </li> <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                       Training & Development
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="text-sm">
                  <p className="font-bold tracking-wide text-base">
                    Technical Services
                  </p>
                  <ul className="mt-2 space-y-2">
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                       Mobile app development
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Web Development
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Cloud Computing
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Data Analytics
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Digital Marketing
                      </a>
                    </li> <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Graphic Designing
                      </a>
                    </li> <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Video Editing
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="text-sm">
                  <p className="font-bold tracking-wide text-base">
                    Company
                  </p>
                  <ul className="sm:hidden md:block mt-2 space-y-2">
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300"
                      >
                        About us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Our Team
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Careers
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        FAQs
                      </a>
                    </li><li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Terms & Conditions
                      </a>
                    </li><li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Policies
                      </a>
                    </li><li>
                      <a
                        href="/"
                        className="transition-colors duration-300 "
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                
                  



                </div>
               
              </div>
            </div>
            <div className="flex flex-col justify-between pt-5 pb-10 border-t border-kushLight-secondary sm:flex-row">
              <p className="text-sm dark:text-gray-100">
                © Copyright 2021 <a href="/" className="text-kushLight-primary">KUSH Fintech Pvt. Ltd.</a> <br />
                <span className="text-xs"> KUSH logo, brandmark, name are registered trademark of KUSH Fintech Pvt. Ltd.</span>
              </p>
              <div className="flex justify-center items-center mt-4 space-x-4 sm:mt-0">
                <a
                  href="https://www.facebook.com/KUSHFintech" target="_blank" rel="noreferrer"
                  className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
                >
                    <FaIcon.FaFacebook></FaIcon.FaFacebook>
                </a>
                <a
                  href="https://www.instagram.com/kushfintech/" target="_blank" rel="noreferrer"
                  className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
                >
                  <FaIcon.FaInstagram></FaIcon.FaInstagram>
                </a>
                <a
                  href="https://www.linkedin.com/company/kush-fintech/" target="_blank" rel="noreferrer"
                  className="transition-colors duration-300 text-deep-purple-100 hover:text-teal-accent-400"
                >
                 <FaIcon.FaLinkedinIn></FaIcon.FaLinkedinIn>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
