import flutterIcon from '../../assets/img/logo/techslogo/flutterlogo.js'


const technologiesUsed = [
  {
    "categoryName": "Financial",
    "subCategories": [
      {
        "subCategoryName": "All",
        "items": [
          {
            "name": "Tableau",
            "icon": "https://logos-world.net/wp-content/uploads/2021/10/Tableau-Logo.png"
          },
          {
            "name": "Microsoft Power BI",
            "icon": "https://logos-world.net/wp-content/uploads/2022/02/Microsoft-Power-BI-Symbol.png"
          },
          
        ]
      },
      {
        "subCategoryName": "Projection",
        "items": [
          {
            "name": "Tableau",
            "icon": "https://logos-world.net/wp-content/uploads/2021/10/Tableau-Logo.png"
          },
          {
            "name": "Microsoft Power BI",
            "icon": "https://logos-world.net/wp-content/uploads/2022/02/Microsoft-Power-BI-Symbol.png"
          },
        ]
      },
      
    ]
  },
  {
    "categoryName": "Technical",
    "subCategories": [
      {
        "subCategoryName": "All",
        "items": [
          {
            "name": "Flutter",
            "icon": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkR2BrlC68ZIblzTujf5dyShFwJnIkgYkxKQwelPNCixc5Of5dN3vb8QAPgzmHTHnisbM&usqp=CAU"
          },
          {
            "name": "Kotlin",
            "icon": "https://ih1.redbubble.net/image.1060767194.0684/pp,840x830-pad,1000x1000,f8f8f8.jpg"
          },
          {
            "name": "Java",
            "icon": "https://1000logos.net/wp-content/uploads/2020/09/Java-Logo.png"
          },
          {
            "name": "React Native",
            "icon": "https://www.datocms-assets.com/45470/1631026680-logo-react-native.png"
          },
          {
            "name": "React",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png"},
          {
            "name": "Angular",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/640px-Angular_full_color_logo.svg.png"
          },
          {
            "name": "Javascript",
            "icon": "https://www.freepnglogos.com/uploads/javascript-png/javascript-logo-transparent-logo-javascript-images-3.png"
          },
          {
            "name": "Next JS",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Nextjs-logo.svg/1200px-Nextjs-logo.svg.png"
          },
          {
            "name": "Node JS",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/1200px-Node.js_logo.svg.png"
          },
          {
            "name": "Express JS",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/6/64/Expressjs.png"
          },
          {
            "name": "Django",
            "icon": "https://www.djangoproject.com/m/img/logos/django-logo-negative.png"
          },
          {
            "name": "WordPress",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Wordpress_Blue_logo.png/1200px-Wordpress_Blue_logo.png"
          },
         
          {
            "name": "Squarespace",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/7/75/Squarespace_Logo_2019.png"
          },
          {
            "name": "Mongo DB",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/2560px-MongoDB_Logo.svg.png"
          },
          {
            "name": "My SQL",
            "icon": "https://download.logo.wine/logo/MySQL/MySQL-Logo.wine.png"
          },
         
        
        ]
      },
      {
        "subCategoryName": "Mobile",
        "items": [
          {
            "name": "Flutter",
            "icon": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkR2BrlC68ZIblzTujf5dyShFwJnIkgYkxKQwelPNCixc5Of5dN3vb8QAPgzmHTHnisbM&usqp=CAU"
          },
          {
            "name": "Kotlin",
            "icon": "https://ih1.redbubble.net/image.1060767194.0684/pp,840x830-pad,1000x1000,f8f8f8.jpg"
          },
          {
            "name": "Java",
            "icon": "https://1000logos.net/wp-content/uploads/2020/09/Java-Logo.png"
          },
          {
            "name": "React Native",
            "icon": "https://www.datocms-assets.com/45470/1631026680-logo-react-native.png"
          },
        ]
      },
     
      {
        "subCategoryName": "Frontend",
        "items": [
          {
            "name": "React",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png"},
          {
            "name": "Angular",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Angular_full_color_logo.svg/640px-Angular_full_color_logo.svg.png"
          },
          {
            "name": "Javascript",
            "icon": "https://www.freepnglogos.com/uploads/javascript-png/javascript-logo-transparent-logo-javascript-images-3.png"
          },
          {
            "name": "Next JS",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Nextjs-logo.svg/1200px-Nextjs-logo.svg.png"
          },
        ]
      },
      {
        "subCategoryName": "Backend",
        "items": [
          {
            "name": "Node JS",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/1200px-Node.js_logo.svg.png"
          },
          {
            "name": "Express JS",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/6/64/Expressjs.png"
          },
          {
            "name": "Django",
            "icon": "https://www.djangoproject.com/m/img/logos/django-logo-negative.png"
          },
        ]
      },
      {
        "subCategoryName": "CMS",
        "items": [
          {
            "name": "WordPress",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Wordpress_Blue_logo.png/1200px-Wordpress_Blue_logo.png"
          },
         
          {
            "name": "Squarespace",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/7/75/Squarespace_Logo_2019.png"
          },
        ]
      },
      {
        "subCategoryName": "Database",
        "items": [
          {
            "name": "Mongo DB",
            "icon": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/2560px-MongoDB_Logo.svg.png"
          },
          {
            "name": "My SQL",
            "icon": "https://download.logo.wine/logo/MySQL/MySQL-Logo.wine.png"
          },
        ]
      },
      {
        "subCategoryName": "Desktop",
        "items": [
          {
            "name": "Flutter",
            "icon": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkR2BrlC68ZIblzTujf5dyShFwJnIkgYkxKQwelPNCixc5Of5dN3vb8QAPgzmHTHnisbM&usqp=CAU"
          },
          {
            "name": "Swing",
            "icon": "https://www.qfs.de/fileadmin/Webdata/logos-icons/java-swing-schriftzug.png"
          },
        ]
      },
    ]
  },
]

export default technologiesUsed;