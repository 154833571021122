import React, { Component } from 'react';
import FinancialFeatures from './features/FinancialFeatures';
import FinancialHero from './components/FinancialHero';
import CallToAction from './components/CallToAction';

export default class Financial extends Component {
    render() {
        return (
            <div>
                <FinancialHero/>
                <FinancialFeatures/>
                {/* <CallToAction></CallToAction> */}
            </div>
        )
    }
}
