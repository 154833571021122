// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
// import TestimonialSection from './components/testimonials/TestimonialSection';
import Footer from './components/Footer';
import {
  BrowserRouter as Router,
} from "react-router-dom";

// import NavBarApp from './components/navbar/NavBarApp';

import AppRoutes from './routes/AppRoutes';

// import AnimatedNavbar from './components/navbar/AnimatedNavbar';

// import styled from "styled-components";

// const AppContainer = styled.div`
//   background: #fff;
//   display: flex;
//   flex-direction: column;

//   > div:first-of-type {
//     z-index: 100;
//   }
// `

function App() {
  return (
    <Router>
    {/* <NavBarApp></NavBarApp> */}
    <div className="font-poppins">
      <Navbar />
       
      {/* <AppContainer>
      <AnimatedNavbar duration={300} ></AnimatedNavbar>
      </AppContainer> */}
      <AppRoutes/>
      {/* <Home></Home> */}
      {/* <AppRoutes/> */}
      {/* Footer */}
      <Footer></Footer>



    </div>
    </Router>
  );
}

export default App;
